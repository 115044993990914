@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbS2lBkm8.woff2") format("woff2");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbTGlBkm8.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbQWlBkm8.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Product Sans;
  font-style: normal;
  font-weight: 500;
  src: local(Product Sans Medium), local(ProductSans-Medium), url("https://fonts.gstatic.com/s/productsans/v9/pxicypQkot1TnFhsFMOfGShd5PSbT2lB.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Product Sans, Helvetica, Arial, sans-serif;
  font-size: 100%;
  font-weight: 300;
  overflow: hidden;
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

footer > a > img {
  float: right;
  width: 30px;
  position: relative;
  bottom: 5px;
  right: 5px;
}

.main {
  text-align: center;
  position: relative;
}

.avatarContainer {
  filter: drop-shadow(10px 10px 20px #000);
  border-radius: 100px;
  width: max-content;
  height: max-content;
  margin: 0 auto;
}

.avatar {
  pointer-events: all;
  filter: none;
  border-radius: 100px;
  width: 175px;
  height: 175px;
}

.topping > .container-top {
  min-width: 50%;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.summary {
  min-height: 75vh;
}

.summary > h1 {
  filter: drop-shadow(10px 10px 20px #000);
  background-size: 200% 100%;
  border-radius: 100px;
  width: max-content;
  margin: 20px auto;
  font-size: 650%;
  font-weight: 500;
  animation: 3s infinite alternate gradient;
}

h3 {
  font-size: 250%;
}

.list-group {
  text-align: center;
}

.ulshadow {
  filter: blur(12px);
  z-index: 1;
  background: linear-gradient(45deg, #b4b4b4 0%, #7c4cde 100%) 0 0 / 200% 100%;
  border-radius: 100px;
  height: 175px;
  margin: 0 auto;
  animation: 3s infinite alternate gradient;
  position: relative;
}

.ulshadow:first-of-type {
  width: 610px;
}

.ulshadow:last-of-type {
  width: 490px;
  top: -160px;
}

ul {
  z-index: 5;
  background: linear-gradient(45deg, #b4b4b4 0%, #7c4cde 100%) 0 0 / 200% 100%;
  border-radius: 100px;
  width: max-content;
  margin: 0 auto;
  padding: 10px;
  animation: 3s infinite alternate gradient;
  position: relative;
  top: -160px;
}

ul:last-of-type {
  top: -320px;
}

ul li {
  filter: drop-shadow(10px 10px 20px #000);
  padding-left: 25px;
  list-style-type: none;
  display: inline-block;
  position: relative;
  top: 2px;
}

ul li:first-of-type {
  padding-left: 0;
}

ul li img {
  opacity: .8;
  width: 125px;
  transition: all .4s ease-in-out;
  position: relative;
}

ul li img:hover {
  transform: scale(1.025);
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}
/*# sourceMappingURL=index.8237e27c.css.map */
